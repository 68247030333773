export const data = [
	{
		to: '/',
		text: 'Servicios',
		id: 'about',
	},
	{
		to: '/pricing',
		text: 'Cursos',
	},
	{
		to: '/signup',
		text: 'Clientes',
	},
];