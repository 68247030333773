import React from 'react';

import { LuSatellite } from 'react-icons/lu';
import { GiDeliveryDrone, GiAirBalloon } from 'react-icons/gi';
import { BiChart, BiNetworkChart, BiChip } from 'react-icons/bi';
import { GrDocumentConfig, GrWorkshop } from 'react-icons/gr';
const iconStyle = (Icon) => <Icon size="3rem" color="#0f0f0f" />;

export const FeaturesData = [
	{
		name: 'Consultoría',
		description: 'We offer the best data security to our clients, which makes us stand out',
		icon: iconStyle(BiChart),
		imgClass: 'one',
	},
	{
		name: 'Capacitación, Cursos y Talleres',
		description: 'Our system is easy to use and integrate',
		icon: iconStyle(GrWorkshop),
		imgClass: 'two',
	},
	{
		name: 'Conferencias',
		description: 'Our code is written in highest standards, which makes it highly sustainable',
		icon: iconStyle(BiNetworkChart),
		imgClass: 'three',
	},
	{
		name: 'Certificaión Industria 4.0',
		description: 'Our team is available at all times in case you need us',
		icon: iconStyle(BiChip),
		imgClass: 'four',
	},
	{
		name: 'Registro De Patentes',
		description: 'We offer the highest value/cost ratio',
		icon: iconStyle(GrDocumentConfig),
		imgClass: 'five',
	},
	{
		name: 'Lanzamientos De Prueba Con Drones',
		description:
			'Our servers are located all over the world, therefore improving scalability and speed ',
		icon: iconStyle(GiDeliveryDrone),
		imgClass: 'six',
	},
	{
		name: 'Lanzamientos De Prueba Con Globos Estratosféricos',
		description:
			'Our servers are located all over the world, therefore improving scalability and speed ',
		icon: iconStyle(GiAirBalloon),
		imgClass: 'one',
	},
	{
		name: 'Diseño y Aplicación De Picosatélite',
		description:
			'Our servers are located all over the world, therefore improving scalability and speed ',
		icon: iconStyle(LuSatellite),
		imgClass: 'two',
	},
];