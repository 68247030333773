import React from "react";
import GlobalStyle from "./globalStyles";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Navbar from "./components/Navbar/Navbar";

import Home from "./pages/Home";
import Signup from "./pages/SignUp"
import Pricing from "./pages/Pricing"

function App() {
  return (
    <Router>
      <GlobalStyle />
      <Navbar />
      <Routes>
        <Route path="/" exact Component={Home} />
        <Route path="/" exact Component={Signup} />
        <Route path="/" exact Component={Pricing} />
      </Routes>
    </Router>
  );  
}

export default App;