import React from 'react'
import { Link } from 'react-router-dom'
import { Button, Container, MainHeading } from '../../globalStyles'
import { HeroVideo, HeroSection, HeroText, ButtonWrapper, HeroButton } from './HeroStyles'
import { BsLinkedin } from 'react-icons/bs'

const Hero = () => {
  return (
    <HeroSection>
        <HeroVideo src='./assets/happeniot.mp4' autoPlay muted />
        <Container>
            <MainHeading>
                En HappenIoT
            </MainHeading>
            <HeroText>
                nos dedicamos a ofrecer servicios innovadores de consultoría especializada en sistemas inteligentes con internet de las cosas e inteligencia artificial
            </HeroText>
            <ButtonWrapper>
                <Link onClick={() => window.location = 'mailto:happeniot@gmail.com' }>
                    <Button>Contáctanos</Button>
                </Link>
                <Link onClick={() => window.location = 'https://www.linkedin.com/company/happeniot/' }>
                    <HeroButton>
                        <BsLinkedin />
                    </HeroButton>
                </Link>
                
            </ButtonWrapper>
        </Container>
    </HeroSection>
  )
}

export default Hero