export const heroOne = {
	reverse: true,
	inverse: true,
	topLine: {
		text: 'Te acompañamos en la transformación digital de tu empresa',
	},
	headline: "Te acompañamos en la transformación digital de tu empresa",
	description: 'Implementamos las mejores prácticas para conseguir la perfecta armonía entre humanos y tecnología',
	buttonLabel: 'Conoce más',
	imgStart: 'start',
	img: './assets/svg/Deal.svg',
	start: 'true',
};

export const heroTwo = {
	reverse: false,
	inverse: false,
	topLine: {
		text: 'Diseño y Desarrollo',
	},
	headline: 'The best practices',
	description: 'Desarrollamos tu proyecto a la medida de tus necesidades',
	buttonLabel: 'Nuestros Proyectos',

	linkTo: '/more',
	imgStart: 'start',
	img: './assets/svg/Connection.svg',
	start: 'true',
};

export const heroThree = {
	reverse: true,
	inverse: true,
	topLine: {
		text: 'Aplicación de modelos sostenibles',
	},
	headline: 'Why us? ',
	description:
		'Nuestra misión es implementar las tecnologías y metodologías más innovadoras para asegurar el crecimiento de tu proyecto',
	buttonLabel: '¡Contáctanos!',

	linkTo: '/download',
	imgStart: '',
	img: './assets/svg/ChartUp.svg',
	start: 'true',
};